<template>
  <div>
    <div class="flex justify-between items-center">
      <span>
        <slot name="title"></slot>
      </span>
    </div>
    <Swiper
      :slides-per-view="1"
      :space-between="16"
      :loop="true"
      :breakpoints="{
        768: {
          slidesPerView: 'auto',
          spaceBetween: 30,
        }
      }"
      :modules="[SwiperNavigation]"
      :navigation="{
        nextEl: `.custom-swiper-next-${uuid}`,
        prevEl: `.custom-swiper-prev-${uuid}`,
      }"
      @swiper="onSwiper"
    >
      <SwiperSlide
        class="slide"
        v-for="(event, $index) in props.events"
        :key="$index"
      >
        <NuxtLink :to="`/events/${event.id}`">
          <FeaturedEventCard
            :event="event"
            :index="$index"
            @slide="handleSlide"
          />
        </NuxtLink>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup lang="ts">
import { useId } from 'vue';
import { Swiper as SwiperType } from 'swiper';
import FeaturedEventCard from '@/components/events/FeaturedEventCard.vue';
import WellIcon from '@/components/common/WellIcon.vue';

const uuid = useId()
const swiperInstance = ref<SwiperType | null>(null);

const props = defineProps<{
  events: EventResponse[]
}>()

const onSwiper = (swiper: SwiperType) => {
  swiperInstance.value = swiper;
}

const handleSlide = (direction: 'left' | 'right') => {
  if (swiperInstance.value) {
    if (direction === 'left') {
      swiperInstance.value.slidePrev();
    } else {
      swiperInstance.value.slideNext();
    }
  }
}
</script>

<style scoped>
.slide {
  width: 100%; /* Full width on mobile */
}

@media (min-width: 768px) {
  .slide {
    width: 80%; /* Adjust this value to control how much of the next slide is visible */
  }
}

.custom-swiper-button.swiper-button-disabled {
  display: none;
}
</style>